import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconButton, Button } from "@mui/material";
import Svg from "../svg";
// services
import { icons } from "@local/theme";

const TableEditorActions = (props) => {
	const { t } = useTranslation();
	const handleExportClick = () => {
		const jsonData = JSON.stringify(props.tableItems, null, 2);
		const blob = new Blob([jsonData], {type: "application/json"});
		const url = URL.createObjectURL(blob);
		const tempLink = document.createElement("a");
		tempLink.href = url;
		tempLink.setAttribute("download", `${props.tableId}.json`);
		tempLink.click();
	};

	return (
		<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: (props.isCustomEditor ? "12px 0" : "0"), width: "100%"}}>
			<div style={{ fontWeight: "bold", marginLeft: "5px" }}>{t("teditor.objects")}</div>
			{props.isCustomEditor ?
				<div>
					<Button
						variant="contained"
						disabled={!props.hasWriteAccess}
						startIcon={<Svg src="navigation/add.svg" />}
						onClick={() => (props.onClickHandler(false))}
					>
						{t("teditor.newEntry")}
					</Button>
					<Button
						variant="contained"
						disabled={props.loading}
						startIcon={<icons.Download />}
						onClick={handleExportClick}
						style={{marginLeft: "8px"}}
					>
						{t("gateway.exportTable")}
					</Button>
				</div>
				:
				<div>
					<IconButton
						title={t("teditor.add")}
						disabled={!props.hasWriteAccess}
						onClick={() => (props.onClickHandler(false))}
					>
						<Svg src="navigation/add.svg" />
					</IconButton>
					<IconButton
						title={t("teditor.duplicate")}
						disabled={(props.tableItemSelected === null || !props.hasWriteAccess)}
						onClick={() => (props.onClickHandler(true))}
					>
						<icons.CopyContent />
					</IconButton>
					<Button
						variant="contained"
						size="small"
						disabled={props.loading}
						startIcon={<icons.Download />}
						onClick={handleExportClick}
						style={{marginLeft: "8px"}}
					>
						{t("gateway.exportTable")}
					</Button>
				</div>
			}
		</div>
	);
};

TableEditorActions.defaultProps = {
	isCustomEditor: false,
	tableItemSelected: null
};

TableEditorActions.propTypes = {
	isCustomEditor: PropTypes.bool,
	hasWriteAccess: PropTypes.bool.isRequired,
	tableItemSelected: PropTypes.object,
	onClickHandler: PropTypes.func.isRequired,
	tableItems: PropTypes.arrayOf(PropTypes.object).isRequired,
	tableId: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired
};

export default TableEditorActions;
